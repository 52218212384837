<template>
    <div class="container">
        <nav-top :list="nav.list" :current-key="nav.currentKey" />

        <template v-if="nav.currentKey === 2">
            <div class="list-option">
                <div @click="() => $refs.paymentHistorySortModal.show()" class="ui-btn">
                    {{ activedSortOption.label }}
                    <icon class="icon" src="img/common/tab-unfold.png" width="1.2rem" height="5.5rem" :cover="false" />
                </div>
                <div @click="selectPayDate" class="ui-btn">
                    {{ payDateText }}
                    <icon class="icon" src="img/common/tab-unfold.png" width="1.2rem" height="5.5rem" :cover="false" />
                </div>
            </div>
        </template>

        <ul class="offer-list" ref="container">
            <template v-for="(item, itemKey) in nav.list">
                <template v-if="nav.currentKey === itemKey">
                    <template v-if="!init || offerData[nav.currentKey].length">
                        <template v-for="(row, key) in offerData[nav.currentKey]">
                            <o-item :key="`${itemKey}:${row.offerUid || key}`" :row="row" />
                            <div class="ui-border-line" :key="`${itemKey}:${row.offerUid || key}:line`"></div>
                        </template>
                    </template>
                    <blank-search :key="itemKey" v-else :text="item.emptyText" />
                </template>
            </template>
        </ul>

        <!-- <Modal ref="paymentHistorySortModal" :useFooter="false">
            <ul slot="body">
                <li
                    class="text-center"
                    v-for="(row, key) in sortOptions"
                    :key="key"
                    @click="onClickPaymentHistorySortModalItem(row)"
                    :class="{ on: paymentHistoryPayload.orderBy === row.value }"
                >
                    {{ row.label }}
                    <div v-show="key < sortOptions.length - 1" class="ui-border-line"></div>
                </li>
            </ul>
        </Modal> -->
    </div>
</template>

<script>
import OItem from '@/components/client/OItem.vue'
import BlankSearch from '@/components/blank/BlankList.vue'
import NavTop from '@/components/common/NavTop.vue'
import Modal from '@/components/common/Modal.vue'
import Icon from '@/components/common/Icon.vue'

export default {
    name: 'ArtistMyOffer',
    components: {
        OItem,
        BlankSearch,
        NavTop,
        Modal,
        Icon,
    },
    data() {
        let navBtnCb = (key = 0) => {
            this.que.offerData = this.que.offerData.map(() => false)
            this.scroll.lastY[this.nav.currentKey] = this.$refs.container.scrollTop
            this.nav.currentKey = key

            this.$router.replace({
                query: {
                    nav: key,
                },
            })
            this.$nextTick().then(() => {
                this.$refs.container.scrollTop = this.scroll.lastY[this.nav.currentKey]
                this.getOfferData(this.offerData[this.nav.currentKey].length === 0)
            })
        }
        return {
            init: false,
            offerData: {
                0: [],
                1: [],
                // 2: [],
            },
            offset: {
                offerData: [0, 0],
            },
            que: {
                offerData: [false, false],
            },
            scroll: {
                lastY: [0, 0],
            },
            paymentHistoryPayload: {
                orderBy: 'payDate',
                payDate: undefined,
            },
            nav: {
                list: [
                    {
                        label: '1:1 의뢰',
                        action: () => navBtnCb(0),
                        request({ offset = 0, artistidx }) {
                            return {
                                method: 'post',
                                url: `/client/offer/${offset}/20`,
                                data: {
                                    artistidx,
                                    mode: 'direct',
                                    proPrice: 'y',
                                    nowState: 'ing,wait,cancel,autoEnd,selfEnd',
                                },
                            }
                        },
                        emptyText: `1:1 견적 문의가 도착하면\n여기에 보여요.\n아직은 문의가 없네요:)`,
                    },
                    {
                        label: '지원한 의뢰',
                        action: () => navBtnCb(1),
                        request({ offset = 0 }) {
                            return {
                                method: 'get',
                                url: `/artist/offerPro/${offset}/20`,
                                params: {
                                    nowState: 'ing,wait,cancel,autoEnd,selfEnd',
                                },
                            }
                        },
                        emptyText: `지원한 의뢰가 없습니다.\n어서 프로필을 등록하고\n원하는 의뢰에 지원하세요!`,
                    },
                    // {
                    //     label: '결제 내역',
                    //     action: () => navBtnCb(2),
                    //     request({ offset = 0, artistidx }) {
                    //         return {
                    //             method: 'post',
                    //             url: `/client/offer/${offset}/20`,
                    //             data: {
                    //                 artistidx,
                    //                 proPrice: 'y',
                    //                 nowState: 'pay,end',
                    //                 ...this.paymentHistoryPayload,
                    //             },
                    //         }
                    //     },
                    //     emptyText: `결제(섭외)된 건이 없습니다.\n섭외율을 높이고 싶다면\n프로필을 꾸준히 관리하세요!`,
                    // },
                ],
                currentKey: 0,
            },
            history: {
                exit: false,
            },
        }
    },
    computed: {
        userData: {
            get() {
                return this.$store.state.user.userData
            },
        },
        profiles(){
            return this.$store.getters.getArtistProfiles;
        },
        pickArtistidx() {
            return this.profiles.find(v => v.isPickPro == true )?.artistidx || this.profiles[0]?.artistidx
        },
    },
    watch: {
        // 'paymentHistoryPayload.payDate'() {
        //     this.getOfferData(true)
        // },
        'pickArtistidx': function(newValue, oldValue) {
            // 대표프로필 변경시 offerData 초기화
            this.getOfferData(true)
        },
    },
    created() {
        this.getArtistProfiles()
        this.$eventBus.$on('refresh', route => {
            if (route.path === '/artist/my-offer') {
                this.$nextTick()
                    .then(() => {
                        this.$refs?.container?.scrollTo?.({ top: 0 })
                    })
                    .then(() => {
                        this.getArtistProfiles()
                        this.getOfferData(true)
                    })
            }
        })
    },
    mounted() {
        this.$refs.container.addEventListener('scroll', this.getOfferDataScroll, false)

        this.$root.$on('update:offerData', (offerUid, data) => {
            if ((offerUid, data)) {
                const list = this.offerData[this.nav.currentKey]
                const index = list.findIndex(row => row.offerUid === offerUid)
                if (index !== -1 && list[index]) {
                    Object.assign(list[index], data)
                }
            }
        })
    },
    beforeRouteEnter(to, from, next) {
        next(Vue => {
            const navNextKey = Vue.$route.query?.nav
            if (navNextKey) {
                Vue.nav.currentKey = Number(navNextKey)
            }

            // if (Vue.nav.currentKey === 2) {
            //     if (Vue.$route.query.payDate) {
            //         Vue.paymentHistoryPayload.payDate = Vue.$route.query.payDate
            //         Vue.paymentHistoryPayload.orderBy = 'payDate'
            //     } else if (Vue.paymentHistoryPayload.payDate) {
            //         Vue.paymentHistoryPayload.payDate = undefined
            //         Vue.paymentHistoryPayload.orderBy = 'payDate'
            //     }
            // }

            const lastY = Vue.scroll.lastY[Vue.nav.currentKey]
            const init = Vue.offerData[Vue.nav.currentKey].length > 0

            if (init) {
                Vue.$refs.container.scrollTop = lastY
            } else {
                Vue.getOfferData(true)
            }
        })
    },
    beforeRouteLeave(to, from, next) {
        setTimeout(() => {
            if (this.$store.state.history.back) {
                this.$runToApp({
                    androidCb: () => {
                        if (!this.history.exit) {
                            this.$toast('뒤로 버튼을 한번 더 누르시면 종료됩니다.')
                            this.history.exit = true
                            setTimeout(() => (this.history.exit = false), 2000)
                        } else {
                            location.href = 'hbscheme://appExit'
                        }
                        next(false)
                    },
                    iosCb: () => next(false),
                    webCb: () => next(false),
                })
            } else {
                this.scroll.lastY[this.nav.currentKey] = this.$refs.container.scrollTop
                next()
            }
        })
    },
    methods: {
        getArtistProfiles() {
            this.$store.dispatch('getProfileList')
        },
        getOfferData(init) {
            if (this.que.offerData[this.nav.currentKey]) return
            this.que.offerData[this.nav.currentKey] = true

            if (init) this.offset.offerData[this.nav.currentKey] = 0

            let artistidx = this.pickArtistidx || this.userData.artistidx;
            const req = {
                ...this.nav.list[this.nav.currentKey].request.call(this, {
                    offset: this.offset.offerData[this.nav.currentKey],
                    artistidx: artistidx,
                }),
            }

            this.$http(req)
                .then(({ data }) => {
                    log(...Object.values(req), data)

                    const resultData = this.$lib.resultCheck(data.resultData)

                    if (resultData.success) {
                        let offerData = data.response.offerData || []
                        this.offerData[this.nav.currentKey] = init ? offerData : this.offerData[this.nav.currentKey].concat(offerData)
                        this.offset.offerData[this.nav.currentKey] += offerData.length

                        // if (this.$refs.container?.scrollTop) {
                            // this.$refs.container.scrollTop = 0
                        // }
                    } else {
                        this.$alert(resultData)
                    }
                    return
                })
                .catch(e => {
                    log.error(e)
                    return
                })
                .then(() => {
                    this.init = true
                    setTimeout(() => {
                        this.que.offerData[this.nav.currentKey] = false
                    }, 1000)
                    return
                })
        },
        getOfferDataScroll() {
            if (['ClientMyOffer', 'ArtistMyOffer'].indexOf(this.$route.name) === -1) return

            let scrollTop = this.$refs.container.scrollTop
            let scrollHeight = this.$refs.container.scrollHeight

            if (scrollHeight <= scrollTop + window.innerHeight) {
                this.getOfferData()
            }
        },
        // onClickPaymentHistorySortModalItem(row) {
        //     this.$refs.paymentHistorySortModal?.hide?.()
        //     this.paymentHistoryPayload.orderBy = row.value
        //     this.getOfferData(true)
        // },
        // selectPayDate() {
        //     const { payDate } = this.paymentHistoryPayload

        //     this.$router.push({
        //         path: '/artist/my-paid-offer-filter',
        //         query: {
        //             payDate,
        //         },
        //     })
        // },
    },
}
</script>

<style lang="scss" scoped>
#page > .container {
    display: flex;
    flex-direction: column;
    padding: 0;
    .offer-list {
        padding: 0 3rem;
        flex: 1;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .list-option {
        overflow: hidden;
        border-bottom: 2px solid #eee;

        > div {
            float: left;
            width: 50%;
            text-align: left;
            color: #919191;
            color: #000;
            font-size: 1.8rem;
            line-height: 5.5rem;
            padding: 0 3rem;

            &:first-child {
                border-right: 2px solid #eee;
            }

            .icon {
                float: right;
            }
        }
    }
}
</style>
