<template>
  <div class="no-result">
    <icon src="img/common/no-result-v-ico.png" width="6rem" height="10rem" :cover="false" />
    <p>{{text}}</p>
  </div>
</template>

<script>
import Icon from '@/components/common/Icon'

export default {
  components: {
    Icon,
  },
  props: {
    text: {
      type: String,
      default: '검색결과가 없습니다',
    },
  },
}
</script>

<style lang="scss" scoped>
.no-result {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 50vh;

  .icon {
    margin: 0 auto 2rem;
  }

  p {
    font-size: 2rem;
    text-align: center;
    color: #000;
    white-space: pre-line;
  }
}
</style>
