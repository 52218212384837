<template>
  <div class="ui-navTop">
    <div class="ui-navTop-btns">
      <template v-for="(row, key) in list">
        <div
          :key="key"
          class="ui-navTop-btn"
          :class="{
            'ui-navTop-btn-active': currentKey === key,
          }"
          @click="() => typeof row.action === 'function' && row.action()"
          >
          {{row.label}}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavTop',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    currentKey: {
      type: Number,
      default: 0,
    }
  },
}
</script>

<style lang="scss">
@import '@/assets/css/constant.scss';

.ui-navTop {
  .ui-navTop-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 5.5rem;
    color: #b6b6b6;
    border-bottom: 2px solid #eee;
    .ui-navTop-btn {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2.1rem;
      font-weight: 600;
      border-bottom: 0.3rem solid transparent;
      height: 100%;
      &.ui-navTop-btn-active {
        color: #000;
        border-bottom-color: #000;
      }
    }
  }
}
</style>
