var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "no-result" },
    [
      _c("icon", {
        attrs: {
          src: "img/common/no-result-v-ico.png",
          width: "6rem",
          height: "10rem",
          cover: false
        }
      }),
      _c("p", [_vm._v(_vm._s(_vm.text))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }